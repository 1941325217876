import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ItIconComponent } from "design-angular-kit";
import moment from "moment";

@Component({
    selector: "app-footer",
    standalone: true,
    imports: [TranslateModule, ItIconComponent],
    templateUrl: "./footer.component.html",
})
export class FooterComponent {
    get currentYear() {
        return moment().year();
    }
}
