import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ItNotificationService } from "design-angular-kit";

@Injectable({
    providedIn: "root",
})
export class GlobalErrorHandler implements ErrorHandler {
    labels: any | null = null;
    constructor(
        private readonly notificationService: ItNotificationService,
        private zone: NgZone,
        private translateService: TranslateService
    ) {
        this.translateService.get("it.portal.errors").subscribe((res: any) => {
            this.labels = res;
        });
    }

    handleError(error: any): void {
        if (!(error instanceof HttpErrorResponse)) {
            if (error.rejection) error = error.rejection;
        }

        if (error && error.status) {
            if (error.status === HttpStatusCode.Forbidden) {
                error.error = {};
                error.error.titolo = this.labels.authorizations.title;
                error.error.messaggio = this.labels.authorizations.message;
            }

            if (error.error instanceof Blob) {
                error.error = JSON.parse(this.blobToString(error.error));
            }

            this.zone.run(() => {
                this.notificationService.error(
                    error?.error?.titolo || this.labels.generic.title,
                    error?.error?.messaggio || this.labels.generic.message
                );
            });
        }
    }

    private blobToString(blob: Blob | MediaSource): string {
        const url = URL.createObjectURL(blob);
        const xmlRequest = new XMLHttpRequest();
        xmlRequest.open("GET", url, false);
        xmlRequest.send();
        URL.revokeObjectURL(url);
        return xmlRequest.responseText;
    }
}
