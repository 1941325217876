import { NgStyle } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { RouterLink, RouterLinkActive, RouterOutlet } from "@angular/router";
import { AuthService } from "@app/services/auth.service";
import { TranslateModule } from "@ngx-translate/core";
import { ItCardComponent } from "design-angular-kit";

@Component({
    selector: "app-home",
    standalone: true,
    imports: [TranslateModule, RouterLink, RouterLinkActive, RouterOutlet, ItCardComponent, NgStyle],
    templateUrl: "./home.component.html",
})
export class HomeComponent implements OnInit {
    backgroundImage = `/assets/img/home/home${Math.floor(Math.random() * 5) + 1}.jpg`;

    constructor(protected authService: AuthService) {}

    ngOnInit() {}
}
