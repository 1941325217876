import { HeaderComponent } from "@app/components/header/header.component";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { FooterComponent } from "@app/components/footer/footer.component";
import {
    ItBreadcrumbComponent,
    ItBreadcrumbItemComponent,
    ItNotificationsComponent,
    ItSpinnerComponent,
} from "design-angular-kit";
import { delay, filter } from "rxjs";
import { Breadcrumb } from "@app/models/page";
import { BreadcrumbService } from "@app/services/breadcrumb.service";
import { ViewportScroller } from "@angular/common";
import { LoadingService } from "@app/services/loading.service";
import { LoggedUser } from "@app/models/user";
import { AuthService } from "@app/services/auth.service";
import { environment } from "src/environments/environment";
import { RoutingService } from "@app/services/routing.service";

@Component({
    selector: "app-root",
    standalone: true,
    imports: [
        RouterOutlet,
        HeaderComponent,
        FooterComponent,
        ItBreadcrumbComponent,
        ItBreadcrumbItemComponent,
        ItNotificationsComponent,
        ItSpinnerComponent,
    ],
    templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
    items: Array<Breadcrumb> = [];
    currentBreadcrumb: string | null = null;
    loading = false;
    loggedUser: LoggedUser = new LoggedUser();
    code = this.activatedRoute.snapshot.queryParamMap.get("code");
    state = this.activatedRoute.snapshot.queryParamMap.get("state");
    currentRoute: string | null = null;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private viewportScroller: ViewportScroller,
        private loadingService: LoadingService,
        protected authService: AuthService,
        private routingService: RoutingService
    ) {
        this.viewportScroller.setOffset([0, 105]);
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            this.breadcrumbService.breadcrumbs$.subscribe((breadcrumb) => {
                this.currentBreadcrumb = breadcrumb;
                this.items = this.createBreadcrumbs(this.activatedRoute.root);
            });
        });
        this.routingService.currentRoute$.subscribe((route) => {
            this.currentRoute = route;
        });
    }

    get bypassAccessManager() {
        return environment.accessmanager.bypass;
    }

    get isHome() {
        return this.currentRoute === "";
    }

    ngOnInit(): void {
        this.authService.getUser().subscribe((loggedUser) => {
            this.loggedUser = new LoggedUser(loggedUser);
        });
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const fragment = this.router.parseUrl(this.router.url).fragment;
                fragment
                    ? setTimeout(() => {
                          this.viewportScroller.scrollToAnchor(fragment);
                      })
                    : this.viewportScroller.scrollToPosition([0, 0]);
            }
        });
        this.loadingService.loadingSub.pipe(delay(0)).subscribe((loading) => {
            this.loading = loading;
        });
    }

    createBreadcrumbs(
        route: ActivatedRoute,
        link: string = "",
        breadcrumbs: Array<{ label: string; link: string }> = []
    ): Array<{ label: string; link: string }> {
        const children: ActivatedRoute[] = route.children;
        if (children.length === 0) return breadcrumbs;
        children.forEach((child) => {
            const routeURL: string = child.snapshot.url.map((segment) => segment.path).join("/");
            const label = child.snapshot.data["breadcrumb"] || this.currentBreadcrumb;
            if (routeURL !== "" && label) {
                link += `/${routeURL}`;
                breadcrumbs.push({ label, link });
            }
            return this.createBreadcrumbs(child, link, breadcrumbs);
        });
        return breadcrumbs;
    }
}
