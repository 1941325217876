import { inject } from "@angular/core";
import { Routes } from "@angular/router";
import { HomeComponent } from "@app/components/home/home.component";
import { LoginComponent } from "@app/components/login/login.component";
import { UserDetailsComponent } from "@app/components/users/user-detail/user-detail.component";
import { canActivate, canDeactivate, whereRedirect } from "@app/interceptors/route.interceptor";
import { PageType } from "@app/models/page";
import { QualificationType, Role } from "@app/models/role";

export const routes: Routes = [
    {
        path: "",
        component: HomeComponent,
    },
    {
        path: "accesso",
        data: { breadcrumb: "Accesso alla piattaforma" },
        component: LoginComponent,
    },
    {
        path: "cambia-ruolo",
        data: { breadcrumb: "Cambia ruolo" },
        component: LoginComponent,
    },
    {
        path: "immobili",
        data: { breadcrumb: "Immobili", qualifications: [QualificationType.GET_PROPERTIES] },
        loadChildren: () => import("./components/properties/properties.routes").then((m) => m.routes),
        canActivate: [canActivate],
    },
    {
        path: "fascicoli",
        data: { breadcrumb: "Fascicoli", qualifications: [QualificationType.GET_FOLDERS] },
        loadChildren: () => import("./components/folders/folders.routes").then((m) => m.routes),
        canActivate: [canActivate],
    },
    {
        path: "enti",
        data: {
            breadcrumb: "Enti",
            qualifications: [QualificationType.GET_INSTITUTIONS],
        },
        loadChildren: () => import("./components/institutions/institutions.routes").then((m) => m.routes),
        canActivate: [canActivate],
    },
    {
        path: "utenti",
        data: { breadcrumb: "Utenti", qualifications: [QualificationType.MANAGE_USERS] },
        loadChildren: () => import("./components/users/users.routes").then((m) => m.routes),
        canActivate: [canActivate],
    },
    {
        path: "configurazioni",
        data: { breadcrumb: "Configurazioni", qualifications: [QualificationType.MANAGE_SYSTEM_PARAMETERS] },
        loadChildren: () => import("./components/configurations/configurations.routes").then((m) => m.routes),
        canActivate: [canActivate],
    },
    {
        path: "profilo/:id",
        data: { breadcrumb: "Profilo", pageType: PageType.PROFILE, qualifications: [QualificationType.GET_USERS] },
        component: UserDetailsComponent,
        canActivate: [canActivate],
        canDeactivate: [canDeactivate],
    },
    { path: "dashboard", component: LoginComponent, canActivate: [whereRedirect] },
    { path: "**", component: LoginComponent, canActivate: [whereRedirect] },
];
