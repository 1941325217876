import { Component, OnInit } from "@angular/core";
import { FormGroup, ReactiveFormsModule, UntypedFormControl, Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import {
    ItButtonDirective,
    ItInputComponent,
    ItListComponent,
    ItListItemComponent,
    ItRadioButtonComponent,
    ItValidators,
} from "design-angular-kit";
import { AuthService } from "../../services/auth.service";
import { LoggedUser, LoggedUserRole, User, UserLogin } from "@app/models/user";
import { ActivatedRoute, Router } from "@angular/router";
import { ResultList } from "../../models/resultList";
import { UppercaseDirective } from "@app/directives/uppercase.directive";
import { environment } from "src/environments/environment";
import { HttpErrorResponse } from "@angular/common/http";
import { RoutingService } from "@app/services/routing.service";
import { Subject, takeUntil } from "rxjs";

@Component({
    selector: "app-login",
    standalone: true,
    imports: [
        TranslateModule,
        ItInputComponent,
        ReactiveFormsModule,
        ItButtonDirective,
        ItListComponent,
        ItListItemComponent,
        ItRadioButtonComponent,
        UppercaseDirective,
    ],
    templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
    loginForm = new FormGroup({
        cf: new UntypedFormControl("", [Validators.required, ItValidators.taxCode]),
        nome: new UntypedFormControl(""),
        cognome: new UntypedFormControl(""),
        utenteEnteRuolo: new UntypedFormControl(null),
    });
    user: User = new User();
    roles: LoggedUserRole[] = [];
    code = this.route.snapshot.queryParamMap.get("code");
    state = this.route.snapshot.queryParamMap.get("state");
    loggedUser: LoggedUser = new LoggedUser();
    currentRoute: string | null = null;
    destroySubject: Subject<void> = new Subject();

    constructor(
        protected authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private routingService: RoutingService
    ) {}

    get bypassAccessManager() {
        return environment.accessmanager.bypass;
    }

    get pageTitle() {
        return this.currentRoute === "accesso" ? "it.portal.login.system-access" : "it.portal.users.change-role";
    }

    ngOnInit() {
        this.authService
            .getUser()
            .pipe(takeUntil(this.destroySubject))
            .subscribe((loggedUser) => {
                this.loggedUser = new LoggedUser(loggedUser);
            });
        this.routingService.currentRoute$.pipe(takeUntil(this.destroySubject)).subscribe((currentRoute) => {
            this.currentRoute = currentRoute;
        });
        this.getUser();
    }

    onLogin(markAllAsTouched = true) {
        markAllAsTouched && this.loginForm.markAllAsTouched();
        if (this.loginForm.invalid) return;
        this.authService
            .login(this.loginForm.getRawValue() as UserLogin)
            .pipe(takeUntil(this.destroySubject))
            .subscribe({
                next: () => {
                    this.getUser();
                },
                error: (err: HttpErrorResponse) => {
                    if (err.status === 401) {
                        const loggedUser = new LoggedUser({ utente: new User(this.loginForm.getRawValue()) });
                        this.authService.setUser(loggedUser);
                    }
                },
            });
    }

    getUser() {
        if (this.authService.isUserLoggedIn || this.bypassAccessManager) {
            const getUser = this.authService
                .getUser()
                .pipe(takeUntil(this.destroySubject))
                .subscribe({
                    next: (res: LoggedUser) => {
                        if (res.utente?.cf) {
                            this.user = res.utente;
                            if (res.cambioRuolo && !this.loginForm.value.utenteEnteRuolo) {
                                this.loginForm.get("cf")?.disable();
                                this.bypassAccessManager
                                    ? this.loginForm.patchValue({ cf: this.user.cf })
                                    : this.loginForm.patchValue({
                                          cf: this.user.cf,
                                          nome: this.user.nome,
                                          cognome: this.user.cognome,
                                      });
                                this.loginForm.get("utenteEnteRuolo")?.addValidators(Validators.required);
                                this.authService
                                    .getUserRoles()
                                    .pipe(takeUntil(this.destroySubject))
                                    .subscribe((res: ResultList<LoggedUserRole>) => {
                                        this.roles = res.risultati.map((role) =>
                                            Object.assign(new LoggedUserRole(), role)
                                        );
                                    });
                            } else if (res.utenteEnteRuolo) this.router.navigate(["dashboard"]);
                            else return;
                        }
                        getUser.unsubscribe();
                    },
                });
        } else this.gotoAccessManager();
    }

    gotoAccessManager() {
        if (this.code && this.state && this.state === localStorage.getItem("state")) {
            this.authService
                .getAccessManagerToken(this.code)
                .pipe(takeUntil(this.destroySubject))
                .subscribe((token) => {
                    this.router.navigate([], {
                        relativeTo: this.route,
                        queryParams: {},
                    });
                    this.authService
                        .getAccessManagerUser(token)
                        .pipe(takeUntil(this.destroySubject))
                        .subscribe((res) => {
                            this.loginForm.patchValue({
                                cf: res.attributeMap.fiscalNumber,
                                nome: res.attributeMap.name,
                                cognome: res.attributeMap.familyName,
                            });
                            this.onLogin(false);
                        });
                });
        } else {
            const state = Math.random().toString(36).slice(2, 9);
            localStorage.setItem("state", state);
            const params = new URLSearchParams({
                client_id: environment.accessmanager.clientid,
                redirect_uri: location.href,
                response_type: "code",
                scope: environment.accessmanager.scope,
                state,
            });
            history.replaceState(null, "", "/");
            location.href = `${environment.accessmanager.basepath}${
                environment.accessmanager.authorizeUri
            }?${params.toString()}`;
        }
    }

    logout() {
        const revokeToken = this.authService.localAccessManagerToken;
        this.authService.logoutAccessManager(revokeToken).pipe(takeUntil(this.destroySubject)).subscribe();
        this.authService.logout();
        this.router.navigate([""]);
    }

    ngOnDestroy() {
        this.destroySubject.next();
    }
}
