<section class="section-head section-home" [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')' }"></section>
<div class="container home">
    <div class="row">
        <div class="col-lg-4">
            <div class="card-wrapper">
                <it-card background="true">
                    <h4>
                        <a
                            [routerLink]="authService.isUserLoggedIn ? '/dashboard' : '/accesso'"
                            title="{{ 'it.portal.goto-page' | translate }}: {{ 'it.portal.home.login' | translate }}"
                            >{{ "it.portal.home.login" | translate }}</a
                        >
                    </h4>
                    <p>
                        {{ "it.portal.home.login-info" | translate }}
                    </p>
                    <a
                        class="read-more"
                        [routerLink]="authService.isUserLoggedIn ? '/dashboard' : '/accesso'"
                        title="{{ 'it.portal.goto-page' | translate }}: {{ 'it.portal.home.login' | translate }}"
                    >
                        <span class="text">{{ "it.portal.goto-page" | translate }}</span>
                        <span class="material-symbols-outlined ms-1 fs-5"> arrow_forward </span>
                    </a>
                </it-card>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="card-wrapper">
                <it-card background="true">
                    <h4>
                        <a
                            href="assets/doc/manuale-utente.pdf"
                            target="_blank"
                            title="{{ 'it.portal.goto-document' | translate }}: {{
                                'it.portal.home.manual' | translate
                            }}"
                            >{{ "it.portal.home.manual" | translate }}</a
                        >
                    </h4>
                    <p>
                        {{ "it.portal.home.manual-info" | translate }}
                    </p>
                    <a
                        class="read-more"
                        target="_blank"
                        href="assets/doc/manuale-utente.pdf"
                        title="{{ 'it.portal.goto-document' | translate }}: {{ 'it.portal.home.manual' | translate }}"
                    >
                        <span class="text">{{ "it.portal.goto-document" | translate }}</span>
                        <span class="material-symbols-outlined ms-1 fs-5"> arrow_forward </span>
                    </a>
                </it-card>
            </div>
        </div>
    </div>
</div>
