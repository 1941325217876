import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { HeaderSticky } from "bootstrap-italia";
import { ItIconComponent, ItButtonDirective, ItNavBarModule } from "design-angular-kit";

@Component({
    standalone: true,
    selector: "it-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [TranslateModule, ItIconComponent, ItButtonDirective, ItNavBarModule],
})
export class ItHeaderComponent implements AfterViewInit, OnChanges {
    @Input() light?: boolean;

    @Input() sticky?: boolean;

    @Input() showSlim?: boolean = true;

    @Input() smallHeader?: boolean = true;

    @Input() showSearch?: boolean = true;

    @Input() slimImg: string | undefined;
    @Input() slimTitle: string | undefined;
    @Input() slimTitleLink: string | undefined = "#";

    @Input() loginStyle: "none" | "default" | "full" | "custom" = "none";

    @Output() loginClick: EventEmitter<Event>;

    @Output() searchClick: EventEmitter<Event>;

    @ViewChild("headerWrapper") private headerWrapper?: ElementRef<HTMLButtonElement>;

    @Input() megamenu?: boolean;
    @Input() expand?: boolean = true;

    private stickyHeader?: HeaderSticky;

    constructor() {
        this.loginClick = new EventEmitter<Event>();
        this.searchClick = new EventEmitter<Event>();
    }

    ngAfterViewInit() {
        this.updateListeners();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["sticky"] && changes["sticky"].currentValue == true && !changes["sticky"].firstChange) {
            this.updateListeners();
        }
        if (changes["sticky"] && changes["sticky"].currentValue == false) {
            this.stickyHeader?._elementObj?._unsetSticky();
            this.stickyHeader?._elementObj?.dispose();
            delete this.stickyHeader;
            this.stickyHeader = undefined;
        }
    }

    updateListeners() {
        if (!this.stickyHeader && this.headerWrapper && this.sticky) {
            this.stickyHeader = new HeaderSticky(this.headerWrapper.nativeElement);
        }
    }

    protected emitLoginClick(event: Event): void {
        event.preventDefault();
        this.loginClick.emit(event);
    }

    protected emitSearchClick(event: Event): void {
        event.preventDefault();
        this.searchClick.emit(event);
    }
}
