import { ApplicationConfig, ErrorHandler } from "@angular/core";
import { provideRouter } from "@angular/router";
import { routes } from "./app.routes";
import { provideDesignAngularKit } from "design-angular-kit";
import { TranslateLoader } from "@ngx-translate/core";
import { HttpBackend, provideHttpClient, withInterceptors } from "@angular/common/http";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { httpInterceptor } from "@app/interceptors/http.interceptor";
import { GlobalErrorHandler } from "@app/services/global-error.service";

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideDesignAngularKit({
            translateLoader: (itPrefix: string, itSuffix: string) => ({
                provide: TranslateLoader,
                useFactory: (http: HttpBackend) =>
                    new MultiTranslateHttpLoader(http, [
                        { prefix: itPrefix, suffix: itSuffix },
                        { prefix: "./assets/i18n/" },
                    ]),
                deps: [HttpBackend],
            }),
        }),
        provideAnimationsAsync(),
        provideHttpClient(withInterceptors([httpInterceptor])),
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ],
};
